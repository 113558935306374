import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-900"> 
      <header className="bg-lightblue text-neutral-content py-10"> 
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Learn python programming with easy context for{' '}
            <span className="text-lightblue">all age groups starting from 6.</span>
          </h1>
          <p className="text-2xl mb-8">
            Our in-depth courses teach you how to program python to solve problems.
          </p>
          <Link to="/courses" className="btn btn-secondary">
            Get Started
          </Link>
        </div>
      </header>

      <main className="flex-grow py-10"> 
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-6 text-center text-white">Our Characters</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            {/* Character cards */}
            <div className="character-card">
              <img src={`${process.env.PUBLIC_URL}/characters/novice.jpg`} alt="Novice" className="w-full rounded-lg" />
              <h3 className="text-center mt-2 text-xl text-white">Novice</h3>
            </div>

            <div className="character-card">
              <img src={`${process.env.PUBLIC_URL}/characters/apprentice.jpg`} alt="Apprentice" className="w-full rounded-lg" />
              <h3 className="text-center mt-2 text-xl text-white">Apprentice</h3>
            </div>
            
            <div className="character-card">
              <img src={`${process.env.PUBLIC_URL}/characters/adept.jpg`} alt="Adept" className="w-full rounded-lg" />
              <h3 className="text-center mt-2 text-xl text-white">Adept</h3>
            </div>

            <div className="character-card">
              <img src={`${process.env.PUBLIC_URL}/characters/veteran.jpg`} alt="Veteran" className="w-full rounded-lg" />
              <h3 className="text-center mt-2 text-xl text-white">Veteran</h3>
            </div>

            <div className="character-card">
              <img src={`${process.env.PUBLIC_URL}/characters/master.jpg`} alt="Master" className="w-full rounded-lg" />
              <h3 className="text-center mt-2 text-xl text-white">Master</h3>
            </div>

            <div className="character-card">
              <img src={`${process.env.PUBLIC_URL}/characters/legend.jpg`} alt="Legend" className="w-full rounded-lg" />
              <h3 className="text-center mt-2 text-xl text-white">Legend</h3>
            </div>

          </div>
        </div>
      </main>

    </div>

  );
}

export default HomePage;